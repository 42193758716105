import { TypographyOptions } from '@mui/material/styles/createTypography';

interface ExtendedTypographyOptions extends TypographyOptions {
    footnote: React.CSSProperties;
    giant: React.CSSProperties;
}

export const defaultTheme: any = {
    shadows: [
        'none',
        '2px 3px 3px rgba(0, 0, 0, 0.03)', // $bs-lighter
        '2px 4px 18px rgba(0, 0, 0, 0.05)', // $bs-light
        '0px 3px 8px rgba(0, 0, 0, 0.12)', // $bs-short
        '0px 10px 24px rgba(0, 0, 0, 0.1)', // $bs-large
        '0px 7px 18px rgba(0, 0, 0, 0.2)', // $bs-strong
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
        '0px 7px 18px rgba(0, 0, 0, 0.2)',
    ],
    palette: {
        whiteCta: {
            main: '#fff', // $c-white
            dark: '#42524c', // darken($c-white, 5%)
            contrastText: '#42524c', // $c-black
        },
        primary: {
            main: '#708a81',
            contrastText: '#fff',
        },
        secondary: {
            main: '#dd8e58',
            contrastText: '#fff',
        },
        info: {
            main: '#dd8e58',
            contrastText: '#fff',
        },
        rose: {
            main: '#708a81', // $c-rose-1
            dark: '#708a81', // darken($c-rose-1, 10%)
            contrastText: '#fff', // $c-rose-4
        },
        cta: {
            main: '#708a81', // $c-rose-4
            dark: '#708a81', // darken($c-rose-4, 5%)
            contrastText: '#fff', // $c-white
        },
        warning: {
            main: '#dd8e58', // $c-yellow
            contrastText: '#fff', // $c-green-4
            textTransform: 'lowercase',
            textDecoration: 'underline',
        },
        success: {
            main: '#81c784', // $c-green-flashy
            dark: '#81c784',
        },
        error: {
            main: '#f46957', // $c-red
        },
        text: {
            primary: '#42524c', // $c-green-4
        },
        background: {
            default: '#f6f3ea', // $c-bg
            paper: '#fff',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 450, // mobile-portrait
            md: 750, // mobile
            lg: 1280, // tablet
            xl: 1382, // desktop
        },
    },
    typography: {
        fontFamily: 'Jura, sans-serif',
        fontWeight: 300,
        color: '#42524c', //#383838
        // h4: {
        //     fontFamily: 'Podkova, serif',
        // },
        h6: {
            fontSize: '1.2rem',
        },
    },
};
