import React from 'react';
import { ApolloProvider } from 'react-apollo';
import client from 'src/services/apollo';
import ThemeProvider from 'src/theme/ThemeProvider';

export const wrapRootElement = ({ element }) => {
    return (
        <ApolloProvider client={client}>
            <ThemeProvider>{element}</ThemeProvider>
        </ApolloProvider>
    );
};
