import ApolloClient from 'apollo-boost';

export default new ApolloClient({
    uri:
        process.env.GATSBY_PETDI_CMS_API_URL || 'http://localhost:1337/graphql',
    request: (operation: any) => {
        operation.setContext({
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_PETDI_CMS_API_KEY}`,
            },
        });
    },
});
