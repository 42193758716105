import {
    createTheme,
    ThemeProvider as MThemeProvider,
} from '@mui/material/styles';
import React from 'react';

import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { defaultTheme } from './default';

const theme = createTheme({ ...defaultTheme });

theme.components = {
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                color: '#42524c',
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: '12px',
            },
            notchedOutline: {
                border: '1px solid #d0d7de',
            },
        },
    },
    MuiStepLabel: {
        styleOverrides: {
            root: {
                color: '#42524c',
            },
            label: {
                color: '#42524c',
            },
            labelContainer: {
                color: '#42524c',
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                padding: '0px',
                borderRadius: '20px',
                boxShadow: '0px 10px 24px rgba(0, 0, 0, 0.1)',
                transition: 'all 225ms ease-in-out',
                cursor: 'pointer',
                height: '100%',
                // '&:hover': {
                //     [theme.breakpoints.up('md')]: {
                //         transform: 'scale(1.05)',
                //     },
                // },
            },
        },
    },
    MuiStep: {
        styleOverrides: {
            root: {
                cursor: 'pointer',
                transition: 'all 225ms ease-in-out',
                '&:hover': {
                    [theme.breakpoints.up('md')]: {
                        backgroundColor: 'rgb(229 209 184 / 30%)',
                        borderRadius: '4px',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                    },
                },
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                padding: '8px 20px',
                borderRadius: '20px',
                fontSize: '1rem',
                fontWeight: '700',
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            input: {
                backgroundColor: '#FFFFFF',
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                borderRadius: '12px',
            },
        },
    },
};

interface Props {
    children: React.ReactNode;
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
    return <MThemeProvider theme={theme}>{children}</MThemeProvider>;
};

export default withStyles((theme: Theme) => ({
    '@global': {
        html: {
            margin: 0,
        },
        body: {
            margin: 0,
            color: '#42524c',
            backgroundColor: '#fcfcfb',
        },
        a: {
            color: '#42524c',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
                textDecorationColor: '#fff',
                textUnderlineOffset: '8px',
            },
            '&.underline': {
                textDecoration: 'underline',
            },
        },
        '.cursor-pointer': {
            cursor: 'pointer',
        },
        '.hidden': {
            display: 'none',
        },
        '.underlined': {
            textDecoration: 'underline',
        },
    },
}))(ThemeProvider);
